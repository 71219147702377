import { render, staticRenderFns } from "./Velas.vue?vue&type=template&id=5756aece&scoped=true&"
import script from "./Velas.vue?vue&type=script&lang=js&"
export * from "./Velas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5756aece",
  null
  
)

export default component.exports